

















import { Component, Prop, Vue } from 'vue-property-decorator';
import { UtilisateurMotDePasse } from '@/views/Compte/Profil/models';

@Component({
    name: 'ResetPasswordForm',
})
export default class ResetPasswordForm extends Vue {

    @Prop({ default: false })
    private loading = false;

    private modifmdp: UtilisateurMotDePasse = new UtilisateurMotDePasse(-1, '', '', '');

    public sendEmailToResetPassword() {
        this.$emit('sendEmailToResetPassword');
    }
}
