








































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

import { ApiService } from '@/services/base/ApiService';
import ApiHelper from '@/services/ApiHelper';
import { FileContentResult } from '@/shared/models';

import { FileHelper } from '@/components/AccordsCadres/helpers/FileHelper';
import { UtilisateurStoreMethods } from '@/store/modules/utilisateur/UtilisateurStore';
import { Utilisateur } from '@/models';
import { EtatContratPartenariat } from '@/shared/enums';

@Component({
    name: 'ContratPartenariatUpload',
})
export default class ContratPartenariatUpload extends Vue {

    /**
     * Indique si c'est en mode modification par un admin.
     */
    @Prop({ default: false })
    public modificationAdmin: boolean;

    @Getter(UtilisateurStoreMethods.UTILISATEUR)
    public importedUser: Utilisateur;
    
    @Action(UtilisateurStoreMethods.CHANGE_ETAT_CONTRAT_PARTENARIAT)
    public changeEtatContratPartenariat: (nouvelEtat: EtatContratPartenariat) => void;

    public $refs!: {
        fileInput: HTMLInputElement;
    }

    // Chargement en cours.
    public loading = false;

    // Indique si il y a une erreur dans le fichier choisi.
    public erreurValidationFichier = false;

    /*
     * Indicateurs de l'état du contrat de partenariat.
     */
    public get contratValide() {
        return this.importedUser.etatContratPartenariatId === EtatContratPartenariat.Valide;
    }
    public get contratInvalide() {
        return this.importedUser.etatContratPartenariatId === EtatContratPartenariat.Invalide;
    }
    public get contratEnAttenteValidation() {
        return this.importedUser.etatContratPartenariatId === EtatContratPartenariat.EnAttenteValidation;
    }

    // Conserve les informations du fichier prêt à être uploadé.
    public file: File = null;

    /**
     * Déclenchement input de type file lors du click sur le choix du fichier.
     */
    public pickFile(): void {
        (this.$refs.fileInput as Element & { click: () => void }).click();
    }

    /**
     * Déclenchement de l'envoi du fichier après vérfication de sa validité du fichier et conversion en Base64.
     */
    public sendFile(): void {
        this.loading = true;
        const formData = new FormData();
        formData.append(this.file.name, this.file);

        // Validation du fichier.
        const fileHelper = new FileHelper(this.file.name, this.file.size, this.file.type);
        if (fileHelper.validate()) {

            // Envoi du fichier vers l'api.
            let endpoint = '';
            if (this.modificationAdmin) {
                endpoint = `/contratPartenariat/uploadContratAdmin/${this.importedUser.id}`;
            } else {
                endpoint = "/contratPartenariat/upload";
            }
            const contratService = new ApiService<FormData>(endpoint);
            contratService.post(formData)
                .then(() => {
                    // Si pas d'erreur il faut changer le statut du contrat de partenariat pour que le message d'info pour la validation s'affiche.
                    this.changeEtatContratPartenariat(EtatContratPartenariat.EnAttenteValidation);
                })
                .finally(() => {
                    this.loading = false;
                    this.file = null;
                });
        } else {
            this.loading = false;
            this.erreurValidationFichier = true;
            this.file = null;
        }            
    }

    /**
     * Récupération du fichier.
     * @param file fichier récupérer de la méthode pickFile
     */
    public fileChange(): void {

        // Récupération du fichier.
        this.erreurValidationFichier = false;
        this.file = this.$refs.fileInput.files[0];
    }

    /**
     * Récupération du contrat de partenariat précédemment uploadé ou généré.
     * */
    public recupererContratPartenariat() {
        this.loading = true;

        let endpoint = '';
        if (this.modificationAdmin) {
            endpoint = `/contratPartenariat/recupererContratAdmin/${this.importedUser.id}`;
        } else {
            endpoint = "/contratPartenariat/recuperer";
        }

        const contratService = new ApiService<FileContentResult>(endpoint);
        contratService.getWhereSingle('').then((reponse) => {
            ApiHelper.createAndDownloadBlobFile(reponse.data);
        }).finally(() => this.loading = false);
    }

    /**
     * Valider le contrat.
     * */
    public validerContrat() {
        const apiContratPartenariat = new ApiService<boolean>(`contratPartenariat/validationContrat/${this.importedUser.id}`);
        apiContratPartenariat.put(true).then(() => {
            this.changeEtatContratPartenariat(EtatContratPartenariat.Valide);
        });
    }

    /**
     * Rejeter le contrat.
     * */
    public rejeterContrat() {
        const apiContratPartenariat = new ApiService<boolean>(`contratPartenariat/rejetContrat/${this.importedUser.id}`);
        apiContratPartenariat.put(false).then(() => {
            this.changeEtatContratPartenariat(EtatContratPartenariat.Invalide);
        });
    }
}
