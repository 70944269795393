













































import { Component, Prop, Mixins } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import { UtilisateurEmail } from '@/components/FormsUtilisateur/models/';
import { Utilisateur } from '@/models/';
import { MapUtilisateur } from './helpers/MapUtilisateur';
import FormValidator from './Form.Validator';

@Component({
    name: 'EmailModifForm',
    components: {
        ValidationProvider,
    },
})
export default class EmailModifForm extends Mixins(FormValidator) {
    public $refs!: {
        formEmail: HTMLFormElement;
    };

    @Prop({ default: new Utilisateur() })
    public readonly utilisateur!: Utilisateur;

    private userEmail: UtilisateurEmail = new UtilisateurEmail();
    private currentEmail = '';
    /**
     * Événement qui se déclenche lorsque le mail change.
     */
    public onEmailChanged() {
        this.$emit('EmailEdited', this.userEmail);
    }
    /**
     * Montage de la vue.
     */
    public mounted() {
        this.userEmail = MapUtilisateur.toEmail(this.utilisateur);
        this.userEmail.userId = this.utilisateur.id;
        this.currentEmail = this.userEmail.ancienEmail;
    }
    /**
     * Vérifie si le mail a changé.
     */
    public isEmailChanged(): boolean {
        return (this.currentEmail !== this.userEmail.ancienEmail);
    }
}
