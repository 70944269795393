










import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import ApiHelper from '@/services/ApiHelper';
import { FileContentResult } from '@/shared/models';
import { UtilisateurStoreMethods } from '@/store/modules/utilisateur/UtilisateurStore';
import { Utilisateur } from '@/models';

@Component({
    name: 'LettreEngagementDownload',
})
export default class LettreEngagementDownload extends Vue {

    @Getter(UtilisateurStoreMethods.UTILISATEUR)
    public importedUser: Utilisateur;

    public $refs!: {
        fileInput: HTMLInputElement;
    }

    // Chargement en cours.
    public loading = false;

    /**
     * Génération de la lettre d'engagement pour l'utilisateur connecté.
     * */
    public genererLettreEngagement() {
        this.loading = true;
        return new Promise<FileContentResult[]>((resolve, reject) => {
            this.$http.get(`/utilisateur/genererLettreEngagement/${this.importedUser.id}`).then((response) => {
            response.data.forEach((doc : FileContentResult) => {ApiHelper.createAndDownloadBlobFile(doc);});
        })
        .catch((error: { response: any }) => {
                reject(error.response);
            })
        .finally(() => this.loading = false);
        });
    }
}
