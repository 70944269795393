export class UtilisateurMotDePasse {
    public userId: number;
    public ancienMotDePasse: string;
    public nouveauMotDePasse: string;
    public confirmMotDePasse: string;
    constructor();
    constructor(userId: number, ancienMotDePasse: string, nouveauMotDePasse: string, confirmMotDePasse: string)
    constructor(userId?: number, ancienMotDePasse?: string, nouveauMotDePasse?: string, confirmMotDePasse?: string) {
        this.userId = userId || -1;
        this.ancienMotDePasse = ancienMotDePasse || '';
        this.nouveauMotDePasse = nouveauMotDePasse || '';
        this.confirmMotDePasse = confirmMotDePasse || '';
    }
}
