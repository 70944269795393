














































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { UtilisateurDroitsAcces } from './models';
import { Utilisateur, Profil } from '@/models';
import { Getter, Action } from 'vuex-class';
import { UtilisateurStoreMethods } from '@/store/modules/utilisateur/UtilisateurStore';
import { ApiService } from '@/services/base/ApiService';
import { Profils, TypeCompteSyndic } from '@/shared/enums';
import Confirm from '@/components/Confirm.vue';

@Component({
    name: 'DroitsAccesForm',
    components: {
        Confirm
    },
})
export default class DroitsAccesForm extends Vue {
    /**
     * Définit l'ensemble des droits d'accès pour un utilisateur en dehors de son type de profil.
     * @memberof DroitsAccesForm
     */
    public droitsAccesUtilisateur: UtilisateurDroitsAcces = null;

    /**
     * Type compte syndic migration.
     */
    public typeCompteSyndicId: TypeCompteSyndic = TypeCompteSyndic.Syndic;

    /**
     * Migration en cours
     */
    public loadingMigration: boolean = false;

    // Définition de refs.
    public $refs!: Vue['$refs'] & {
        confirm: {
            open: ((title: string | null, message: string | null, options: { color?: string; width?: number; zIndex?: number }) => Promise<boolean>);
        };
    };

    /**
     * Compte utilisateur entreprise
     */
    public get estCompteEntreprise(): boolean {
        return this.utilisateur && this.utilisateur.profilUtilisateur === Profils.Entreprise;
    };

    /**
     * Getter pour le type de profil de l'utilisateur.
     */
    @Getter(UtilisateurStoreMethods.UTILISATEUR)
    private utilisateur: Utilisateur;

    /**
     * Getter des profils sélectionnables.
     */
    @Getter(UtilisateurStoreMethods.PROFILS)
    private profils: Profil[];

    /**
     * Met à jour l'utilisateur quand ses droits d'accès changent.
     */
    @Action(UtilisateurStoreMethods.UPDATE_DROITS_UTILISATEUR)
    private mettreAJourDroitsUtilisateur: (droits: UtilisateurDroitsAcces) => void;

    /**
     * Récupère les droits à l'ouverture.
     */
    @Watch('utilisateur', { immediate: true })
    public onUtilisateurChanged() {

        // Récupère les droits seulement lors de l'ouverture.
        if (this.utilisateur.id && !this.droitsAccesUtilisateur) {
            const utilisateurApiService = new ApiService<UtilisateurDroitsAcces>('utilisateur/obtenirDroitsAcces');
            utilisateurApiService.get(this.utilisateur.id).then((reponse) => {
                this.droitsAccesUtilisateur = reponse.data.data;
                this.droitsAccesUtilisateur.userId = this.utilisateur.id;
            });
        }
    }

    /**
     * Met à jour les droits d'accès lors d'un changement.
     */
    @Watch('droitsAccesUtilisateur', { deep: true })
    public onDroitsAccesUtilisateurChanged(nouveaux: UtilisateurDroitsAcces, precedents: UtilisateurDroitsAcces) {
        if (precedents) {
            this.mettreAJourDroitsUtilisateur(this.droitsAccesUtilisateur);
        }
    }

    /**
     * Migration compte entreprise => Syndic SDC
     */
    public migrerCompte() {

        this.loadingMigration = true;

        this
            .$refs
            .confirm
            .open("Migration du compte", "Valider la migration du compte ?", { color: 'red', width: 500, zIndex: 200 })
            .then((confirmation: boolean) => {
                if (confirmation) {
                    this.utilisateur.typeCompteSyndicId = this.typeCompteSyndicId;

                    // Migration de l'utilisateur.
                    const api = new ApiService<object>('utilisateur/migrer');
                    api.post(this.utilisateur)
                        .then(response => {
                            this.loadingMigration = false;
                            location.reload();
                        })
                        .catch((error: { response: any }) => {
                            this.loadingMigration = false;
                            console.error(error);
                        });
                }
            });
    }
}
