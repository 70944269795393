































































































































import { Component, Prop, Mixins } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { Getter, Action } from 'vuex-class';
import FormValidator from './Form.Validator';
import { UtilisateurMotDePasse } from '@/views/Compte/Profil/models';
import { Societe, Utilisateur, Civilite } from '@/models';
import { MapUtilisateur } from '@/components/FormsUtilisateur/helpers/MapUtilisateur';
import { ApiService } from '@/services/base/ApiService';
import IdentityForm from '@/components/FormsUtilisateur/IdentityForm.vue';
import SocieteForm from '@/components/FormsUtilisateur/SocieteForm.vue';
import ModifierPasswordForm from '@/components/FormsUtilisateur/ModifierPasswordForm.vue';
import ResetPasswordForm from '@/components/FormsUtilisateur/ResetPasswordForm.vue';
import EmailModifForm from '@/components/FormsUtilisateur/EmailModifForm.vue';
import DroitsAccesForm from '@/components/FormsUtilisateur/DroitsAccesForm.vue';
import ContratPartenariatUpload from '@/components/FormsUtilisateur/ContratPartenariatUpload.vue';
import LettreEngagementDownload from '@/components/FormsUtilisateur/LettreEngagementDownload.vue';
import ValidationAction from '@/components/ValidationAction.vue';
import { UtilisateurEmail } from '@/components/FormsUtilisateur/models/';
import { isEqual, cloneDeep } from 'lodash-es';
import { Profils } from '@/shared/enums';
import { UtilisateurStoreMethods } from '@/store/modules/utilisateur/UtilisateurStore';
import { AuthStoreMethods } from '@/store/modules/auth/AuthStore';
import { UserProfile } from '@/store/modules/auth/types';
import ContratApporteurAffairesList from '@/components/Commun/ContratApporteurAffairesList.vue';
import { ReferentielStoreMethods, getterKeyReferentiel } from '@/store/modules/referentiel/ReferentielStore';
import { TypeValeurReferentielle, ValeurReferentielle } from '@/shared/models';
import AccordsCadresList from '@/components/AccordsCadres/AccordsCadresList.vue';

@Component({
    name: 'ModifierUtilisateur',
    components: {
        IdentityForm,
        SocieteForm,
        EmailModifForm,
        ResetPasswordForm,
        ModifierPasswordForm,
        DroitsAccesForm,
        ValidationAction,
        ValidationProvider,
        ValidationObserver,
        ContratPartenariatUpload,
        AccordsCadresList,
        ContratApporteurAffairesList,
        LettreEngagementDownload,
    },
})
export default class ModifierUtilisateur extends Mixins(FormValidator) {
    public $refs!: {
        formSociete: HTMLFormElement;
        observerIdentity: InstanceType<typeof ValidationObserver>;
        observerEmail: InstanceType<typeof ValidationObserver>;
        observerSocietes: InstanceType<typeof ValidationObserver>;
        // Pour gérer les mots de passe
        formPassword: HTMLFormElement;
        observerPassword: InstanceType<typeof ValidationObserver>;
    };

    @Prop({ default: '' }) public readonly typeCompte!: string;

    private modifmdp: UtilisateurMotDePasse = new UtilisateurMotDePasse(-1, '', '', '');

    /**
     * Désactive les différents boutons lors de l'appel au serveur.
     */
    @Prop() public readonly loading: boolean;

    /**
     * Onglet courant.
     */
    public currentTab: string = null;

    // Les valeurs référentielles.
    @Getter(getterKeyReferentiel(TypeValeurReferentielle.Civilite))
    public civilites: Civilite[];
    @Action(ReferentielStoreMethods.GET_VALEURS_REFERENTIELLES)
    public getValeursReferentielles: (type: TypeValeurReferentielle) => Promise<ValeurReferentielle[]>

    @Getter(UtilisateurStoreMethods.UTILISATEUR)
    private importedUser: Utilisateur;

    @Getter(AuthStoreMethods.USER_PROFILE)
    private getUserProfile: Promise<UserProfile>;

    private userId!: number;
    private userEmail: UtilisateurEmail = new UtilisateurEmail();
    private originalUserSociete: Societe[] = new Array<Societe>();

    /**  Permet de savoir si l'utilisateur est sur son profil. */
    public estUtilisateurCourant = false;

    // Permet de savoir si l'utilisateur connecté est admin;
    public estAdmin = false;

    // Permet de savoir si l'utilisateur connecté est interne;
    public estInterne = true;

    public libelleContrats: string = ''

    public mounted(): void {
        this.getUserProfile.then((profile: UserProfile) => {
            if (profile) {
                this.userId = parseInt(profile.sub, 10);
                this.estAdmin = profile.isAdmin;
                this.estInterne = profile.isInterne;

                // Force la mise à jour de l'utilisateur courant.
                this.setUtilisateurCourant();
            }
        });
        this.getValeursReferentielles(TypeValeurReferentielle.Civilite);

        // On stocke l'état initial de l'onglet sociétés pour savoir s'il est dirty.
        if (this.importedUser) {
            this.originalUserSociete = MapUtilisateur.toSociete(cloneDeep(this.importedUser));

            // Force la sélection du premier onglet s'il s'agit d'un onglet qui est apparu après l'initialisation des tabs.
            setTimeout(() => {
                this.currentTab = this.afficherContratPartenariat || this.afficherLettreEngagement
                    ? 'documents'
                    : 'identity';
            }, 1000);
        }
    }

    public get contratsProps() {
        return {
            editMode: this.estInterne
        };
    }

    public get contratsComponent() {
        if(this.importedUser.profilUtilisateur === Profils.ApporteurAffaires) {
            this.libelleContrats = 'Contrats Apporteur d\'affaires rattachés';
            return ContratApporteurAffairesList;
        }
        else if(this.importedUser.profilUtilisateur === Profils.Installateur ||
        this.importedUser.profilUtilisateur === Profils.Entreprise ||
        this.importedUser.profilUtilisateur === Profils.SyndicSDC) {
            this.libelleContrats = 'Contrats Accords-cadres rattachés';
            return AccordsCadresList;
        }

        return null; 
    }

    public get isImportedUserInterne() {
        return this.importedUser.profilUtilisateur  === Profils.GestionnaireBO 
        || this.importedUser.profilUtilisateur  === Profils.AdministrateurCEE 
        || this.importedUser.profilUtilisateur  === Profils.AdministrateurInformatique 
    }

    /**
     * Permet de savoir si il faut afficher l'onglet avec le contrat de partenariat.
     */
    public get afficherContratPartenariat() {
        return this.importedUser.profilUtilisateur === Profils.Installateur;
    }

    /**
     * Permet de savoir si il faut afficher l'onglet Contrats.
     */
    public get afficherContratsAA() {
        return this.importedUser.profilUtilisateur === Profils.ApporteurAffaires;
    }

    /**
     * Permet de savoir s'il faut afficher l'onglet Droits d'accès.
     */
    public get afficherDroitsAcces() {

        // L'onglet est affiché pour tous les profils externes.
        return this.estAdmin && [Profils.Entreprise, Profils.Installateur, Profils.SyndicSDC, Profils.ApporteurAffaires].some(p => p === this.importedUser.profilUtilisateur);
    }

    /**
     * Permet de savoir si il faut afficher l'onglet avec la lettre d'engagement.
     */
    public get afficherLettreEngagement() {
        return [Profils.Entreprise, Profils.SyndicSDC].some(p => p === this.importedUser.profilUtilisateur);
    }

    public getUserById(id: number): Promise<Utilisateur> {
        const utilisateurEdit = new ApiService<Utilisateur>('utilisateur/obtenir');
        return new Promise<Utilisateur>((resolve, reject) => {
            return utilisateurEdit.get(id).then(response => {
                resolve(response.data.data);
            }).catch((error: { response: any }) => {
                reject(error.response);
            });
        });
    }

    public refreshEmail(value: UtilisateurEmail): void {
        this.userEmail = value;
        this.$emit('EmailEdited', this.userEmail);
    }

    public refreshPassword(value: UtilisateurMotDePasse): void {
        this.modifmdp = new UtilisateurMotDePasse(this.userId, value.ancienMotDePasse, value.nouveauMotDePasse, value.confirmMotDePasse);
        this.$emit('onPasswordEdited', this.modifmdp);
    }

    /**
     * Détermine si l'on peut enregistrer l'écran société, c'est à dire si l'on a modifié une donnée.
     */
    public get isSocieteSubmitDisabled(): boolean {
        return !this.originalUserSociete.length || isEqual(MapUtilisateur.toSociete(cloneDeep(this.importedUser)), this.originalUserSociete);
    }

    /**
     * Permet de définir si l'utilisateur est sur son profil.
     */
    public setUtilisateurCourant(): void {
        this.estUtilisateurCourant = this.importedUser && this.importedUser.id === this.userId && this.importedUser.id > 0;
    }

    /**
     * Permet de faire un clonage profond.
     * @param reponse
     */
    public cloneDeep(value: object): object {
        return cloneDeep(value);
    }
}

