












































































import { Component, Mixins } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import { CompteValidationMixin } from '@/shared/mixins';
import { UtilisateurMotDePasse } from '@/views/Compte/Profil/models';
import CustomCard from '@/components/CustomCard.vue';

@Component({
    name: 'ModifierPasswordForm',
    components: {
        CustomCard,
        ValidationProvider,
    },
})
export default class ModifierPasswordForm extends Mixins(CompteValidationMixin) {

    /**
     * Permet à l'utilisateur de basculer le mode d'affichage du mot de passe afin de vérifier le texte qu'il a entré.
     */
    public afficherAncienPassword: boolean = false;
    public afficherPassword: boolean = false;
    public afficherPasswordConfirm: boolean = false;

    private modifmdp: UtilisateurMotDePasse = new UtilisateurMotDePasse(-1, '', '', '');

    public onPasswordEdited(): void {
        this.$emit('onPasswordEdited', this.modifmdp);
    }
}
